import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Design System",
  "subtitle": "at Contently, 2015-2019",
  "tags": "Project Owner, UX, Front-end",
  "summary": "A tool built for defining interaction standards, design patterns and principles towards a consistent visual language shared across departments.",
  "date": "2015 -2019",
  "order": 1,
  "featuredImage": "./designsystem/cover.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "about-the-initiative"
    }}>{`About the initiative`}</h2>
    <p>{`Contently helps brands create accountable content at scale with an end-to-end content marketing platform. We provided tools to manage workflow across teams, own their editorial calendars, get insights on publications, etc.`}</p>
    <h3 {...{
      "id": "what-is-a-design-system"
    }}>{`What is a Design System?`}</h3>
    <p>{`A design system is a set of guidelines and principles that help to maintain consistency across design and code. It reinforces best practices and provides a shared visual language across departments. It is a tool that continuously evolves with product offerings while helping to avoid design debt and developer chaos. We use it to align our core values and set shared goals to deliver consistent, polished and intuitive user experiences.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design system",
          "title": "Design system",
          "src": "/static/0c827d9995f36bf6a7315655210acb36/1a152/main1.png",
          "srcSet": ["/static/0c827d9995f36bf6a7315655210acb36/a3397/main1.png 400w", "/static/0c827d9995f36bf6a7315655210acb36/a331c/main1.png 800w", "/static/0c827d9995f36bf6a7315655210acb36/1a152/main1.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "why-did-i-start-this"
    }}>{`Why did I start this?`}</h3>
    <p>{`We lost our dedicated product designer, and it divided my time between developing new features, making refinements and building prototypes amongst other tasks. Old projects and new projects alike have all been working in their own designs patterns without a cohesive system across our organization. The redundancies created code churn and was becoming problematic to maintain. I needed a solution to multiply my efforts.`}</p>
    <h3 {...{
      "id": "the-mission"
    }}>{`The mission`}</h3>
    <p>{`My goal was to enable developer productivity. I wanted to unify the language used between stakeholders, engineers, and project managers alike. I deemed the project successful if engineers could put simple interfaces together quickly without relying on my help.`}</p>
    <h3 {...{
      "id": "team"
    }}>{`Team`}</h3>
    <ul>
      <li parentName="ul">{`Project Owner (me)`}</li>
      <li parentName="ul">{`Product & UX Design (me)`}</li>
      <li parentName="ul">{`3+ Engineers (including me)`}</li>
    </ul>
    <h2 {...{
      "id": "initial-patterns"
    }}>{`Initial patterns`}</h2>
    <p>{`We had a monolithic Ruby on Rails application that housed both user-facing and internal projects in one huge repository. Even for tiny design changes, we had to update and test every feature that got touched. I wanted to separate the logic and make implementing design more flexible.`}</p>
    <h3 {...{
      "id": "what-became-a-pattern"
    }}>{`What became a pattern?`}</h3>
    <ul>
      <li parentName="ul">{`A UI element that can be reused across our product.`}</li>
      <li parentName="ul">{`A UI behavior that solves a problem.`}</li>
    </ul>
    <h3 {...{
      "id": "creating-value"
    }}>{`Creating value`}</h3>
    <p>{`I built a demo by picking a pattern that was small but held a large impact. One of the quickest design changes is updating hex colors. A simple replacement can speak volumes for the look and feel of a product. Having a consistent color palette to reference would cut down the time we spent searching for the right shade. I ran a design analysis through our code base to consolidate similar colors and to set a foundation for pattern auditing.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "81.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Consolidating colors",
          "title": "Consolidating colors",
          "src": "/static/997107c7d4606dccfb4d531c65226778/1a152/colors.png",
          "srcSet": ["/static/997107c7d4606dccfb4d531c65226778/a3397/colors.png 400w", "/static/997107c7d4606dccfb4d531c65226778/a331c/colors.png 800w", "/static/997107c7d4606dccfb4d531c65226778/1a152/colors.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "working-backwards-to-standardize"
    }}>{`Working backwards to standardize`}</h3>
    <p>{`Unifying old code and new code to create design guidelines can be complex due to dependencies. I didn't want to block new features, but I also wanted to replace legacy designs with new patterns. I opted for an incremental implementation by seeking opportunities in technical tickets. I convinced engineers and product managers to help adopt our new system.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1492px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "110.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Adding the basics",
          "title": "Adding the basics",
          "src": "/static/20e36fd67b69df7477ae075a740382ad/2647f/basics.png",
          "srcSet": ["/static/20e36fd67b69df7477ae075a740382ad/a3397/basics.png 400w", "/static/20e36fd67b69df7477ae075a740382ad/a331c/basics.png 800w", "/static/20e36fd67b69df7477ae075a740382ad/2647f/basics.png 1492w"],
          "sizes": "(max-width: 1492px) 100vw, 1492px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "growing-our-toolkit"
    }}>{`Growing our toolkit`}</h2>
    <p>{`Once I had the basic patterns down, the project picked up speed and became essential to our day-to-day development. Cutting down on code variations cut down time spent on making unnecessary decisions. We could allocate more time to solving important user problems.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1492px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Expanding beyond basics",
          "title": "Expanding beyond basics",
          "src": "/static/2b6c629bd522744fd5d38a9464ca26bf/2647f/variations.png",
          "srcSet": ["/static/2b6c629bd522744fd5d38a9464ca26bf/a3397/variations.png 400w", "/static/2b6c629bd522744fd5d38a9464ca26bf/a331c/variations.png 800w", "/static/2b6c629bd522744fd5d38a9464ca26bf/2647f/variations.png 1492w"],
          "sizes": "(max-width: 1492px) 100vw, 1492px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "setting-our-tone-of-voice"
    }}>{`Setting our tone of voice`}</h3>
    <p>{`Through pattern auditing, I found that our tone of voice was inconsistent, having had several copywriters in our product life cycle. From losing team members to gaining new colleagues, our library seemed like a great place to document our writing style.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1560px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.50000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tone & voice",
          "title": "Tone & voice",
          "src": "/static/400dae7737d239b39c4119c9e291e8cc/4c04e/voice.png",
          "srcSet": ["/static/400dae7737d239b39c4119c9e291e8cc/a3397/voice.png 400w", "/static/400dae7737d239b39c4119c9e291e8cc/a331c/voice.png 800w", "/static/400dae7737d239b39c4119c9e291e8cc/4c04e/voice.png 1560w"],
          "sizes": "(max-width: 1560px) 100vw, 1560px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "documenting-technical-nuances"
    }}>{`Documenting technical nuances`}</h3>
    <p>{`Sometimes we don’t get the luxury to ask the engineer who did the original implementation about their code. Inline code documentation works only when revisiting, but UI elements are often added in places where pre-existing explanations don’t exist. I started documenting technical quirks which doubled as a way to bring visibility to deeper issues that weren't easy fixes.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "81%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Technical nuances",
          "title": "Technical nuances",
          "src": "/static/a7474d411fefff0fd6f3e4db9c45f258/843f9/tech.png",
          "srcSet": ["/static/a7474d411fefff0fd6f3e4db9c45f258/a3397/tech.png 400w", "/static/a7474d411fefff0fd6f3e4db9c45f258/a331c/tech.png 800w", "/static/a7474d411fefff0fd6f3e4db9c45f258/843f9/tech.png 1440w"],
          "sizes": "(max-width: 1440px) 100vw, 1440px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "utilizing-our-toolkit"
    }}>{`Utilizing our toolkit`}</h2>
    <p>{`As patterns integrated across all our products, review and refinement became necessary. A design was only considered for a pattern if it was reused at least twice. I wanted to make sure not to optimize a pattern before validating that the design was reusable and that it solved a problem.`}</p>
    <h3 {...{
      "id": "simple-patterns-combinations"
    }}>{`Simple patterns combinations`}</h3>
    <p>{`We combined basic patterns to form simple user interfaces as part of larger designs.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Simple patterns",
          "title": "Simple patterns",
          "src": "/static/6642a2a8e8afc51f7aa2044506ac06e3/1a152/ui1-1.png",
          "srcSet": ["/static/6642a2a8e8afc51f7aa2044506ac06e3/a3397/ui1-1.png 400w", "/static/6642a2a8e8afc51f7aa2044506ac06e3/a331c/ui1-1.png 800w", "/static/6642a2a8e8afc51f7aa2044506ac06e3/1a152/ui1-1.png 1600w", "/static/6642a2a8e8afc51f7aa2044506ac06e3/3ed81/ui1-1.png 1770w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "consistent-forms"
    }}>{`Consistent forms`}</h3>
    <p>{`Many of our features were heavy with inputs. To minimize user errors, I wanted to make there was no confusion from one form to another.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "81.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Forms",
          "title": "Forms",
          "src": "/static/064f7f573753051d31938ceab0ba999f/1a152/ui1-2.png",
          "srcSet": ["/static/064f7f573753051d31938ceab0ba999f/a3397/ui1-2.png 400w", "/static/064f7f573753051d31938ceab0ba999f/a331c/ui1-2.png 800w", "/static/064f7f573753051d31938ceab0ba999f/1a152/ui1-2.png 1600w", "/static/064f7f573753051d31938ceab0ba999f/ab549/ui1-2.png 1767w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "prototyping-and-complex-interfaces"
    }}>{`Prototyping and complex interfaces`}</h3>
    <p>{`Teams used the patterns to build functional prototypes that often turned into features. Our complex page structures also became more consistent throughout our platform.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Complex patterns",
          "title": "Complex patterns",
          "src": "/static/e08b0bec21b7bd5f037bd70ebe438da1/1a152/ui2.png",
          "srcSet": ["/static/e08b0bec21b7bd5f037bd70ebe438da1/a3397/ui2.png 400w", "/static/e08b0bec21b7bd5f037bd70ebe438da1/a331c/ui2.png 800w", "/static/e08b0bec21b7bd5f037bd70ebe438da1/1a152/ui2.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Complex patterns",
          "title": "Complex patterns",
          "src": "/static/6a37cf2e67d98933c9bb7fe052dc389b/1a152/ui3.png",
          "srcSet": ["/static/6a37cf2e67d98933c9bb7fe052dc389b/a3397/ui3.png 400w", "/static/6a37cf2e67d98933c9bb7fe052dc389b/a331c/ui3.png 800w", "/static/6a37cf2e67d98933c9bb7fe052dc389b/1a152/ui3.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Complex patterns",
          "title": "Complex patterns",
          "src": "/static/26a2e938fd8b72618948782ba350253e/1a152/ui4.png",
          "srcSet": ["/static/26a2e938fd8b72618948782ba350253e/a3397/ui4.png 400w", "/static/26a2e938fd8b72618948782ba350253e/a331c/ui4.png 800w", "/static/26a2e938fd8b72618948782ba350253e/1a152/ui4.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Complex patterns",
          "title": "Complex patterns",
          "src": "/static/e43d7e526c8d9b349c01369adebdb907/1a152/ui5.png",
          "srcSet": ["/static/e43d7e526c8d9b349c01369adebdb907/a3397/ui5.png 400w", "/static/e43d7e526c8d9b349c01369adebdb907/a331c/ui5.png 800w", "/static/e43d7e526c8d9b349c01369adebdb907/1a152/ui5.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "transitioning-to-components"
    }}>{`Transitioning to components`}</h2>
    <p>{`As the library expanded, we had new challenges.`}</p>
    <ul>
      <li parentName="ul">{`Our global CSS structure often caused unintended cascading changes during merge conflicts between branches releasing similar features.`}</li>
      <li parentName="ul">{`Rails helpers were not the most flexible with implementing design details.`}</li>
      <li parentName="ul">{`Maintaining our documentation was difficult without proper version control. Small updates required full deployments, which was not ideal.`}</li>
    </ul>
    <h3 {...{
      "id": "an-engineering-teams-solution"
    }}>{`An engineering team’s solution`}</h3>
    <p>{`Many of our challenges were prevalent in any monolithic applications so our engineering department explored ways to remove friction from our processes. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Microservices"
      }}>{`Microservice`}</a>{` architecture was a popular solution. Our library was a good candidate as a standalone “single responsibility” application since it would work as a detached service and wouldn't directly affect our customers.`}</p>
    <h3 {...{
      "id": "prioritizing-the-upgrade"
    }}>{`Prioritizing the upgrade`}</h3>
    <p>{`We shifted our technical stack from Rails to React because we found `}<a parentName="p" {...{
        "href": "https://storybook.js.org/"
      }}>{`Storybook`}</a>{` aligned best with our values for building fast and efficient UI components. We also went with `}<a parentName="p" {...{
        "href": "https://emotion.sh/docs/introduction"
      }}>{`Emotion`}</a>{` which leverages objects in Javascript for conditional styling and offered scoped CSS. We wanted to prove that packaging patterns in isolation was beneficial enough to allot time for an upgrade.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "175.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Components library",
          "title": "Components library",
          "src": "/static/8227caf0a731dd78f96ded8aba1088b2/1a152/components1.png",
          "srcSet": ["/static/8227caf0a731dd78f96ded8aba1088b2/a3397/components1.png 400w", "/static/8227caf0a731dd78f96ded8aba1088b2/a331c/components1.png 800w", "/static/8227caf0a731dd78f96ded8aba1088b2/1a152/components1.png 1600w", "/static/8227caf0a731dd78f96ded8aba1088b2/9f64a/components1.png 1638w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "utilizing-our-components-library"
    }}>{`Utilizing our components library`}</h2>
    <p>{`Since all of our projects were being split into smaller standalone projects, it meant that we had to reconsider what was a pattern. Designs such as our navigation became pattern candidates since we required it across multiple repositories.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1360px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "126.74999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Components library",
          "title": "Components library",
          "src": "/static/7b56c2d82591d12480508482a84df495/d8d20/components5.png",
          "srcSet": ["/static/7b56c2d82591d12480508482a84df495/a3397/components5.png 400w", "/static/7b56c2d82591d12480508482a84df495/a331c/components5.png 800w", "/static/7b56c2d82591d12480508482a84df495/d8d20/components5.png 1360w"],
          "sizes": "(max-width: 1360px) 100vw, 1360px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "overall-advantages"
    }}>{`Overall advantages`}</h3>
    <ul>
      <li parentName="ul">{`It was fast to push updates to the documentation, and deployments were independent of any other project.`}</li>
      <li parentName="ul">{`Our features, bugs, and refactors all sat behind different tags preventing unrelated projects from breaking.`}</li>
      <li parentName="ul">{`Having its own version control meant there were no obligations to use any specific version.`}</li>
      <li parentName="ul">{`Error isolation also made debugging issues easier.`}</li>
      <li parentName="ul">{`Projects could choose which components to import keeping them lightweight and avoiding performance issues.`}</li>
    </ul>
    <h3 {...{
      "id": "powerful-prototyping-tool"
    }}>{`Powerful prototyping tool`}</h3>
    <p>{`Storybook came with an improved sandbox that didn’t require additional maintenance on our part. It reduced the time we spent keeping our library usable for non-technical users.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1060px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "114.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Components library",
          "title": "Components library",
          "src": "/static/6d6b9910f97cbe772cfdfd518bbcb47c/a48d4/components2.png",
          "srcSet": ["/static/6d6b9910f97cbe772cfdfd518bbcb47c/a3397/components2.png 400w", "/static/6d6b9910f97cbe772cfdfd518bbcb47c/a331c/components2.png 800w", "/static/6d6b9910f97cbe772cfdfd518bbcb47c/a48d4/components2.png 1060w"],
          "sizes": "(max-width: 1060px) 100vw, 1060px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "component-testing"
    }}>{`Component testing`}</h3>
    <p>{`Components forced us to abstract any tied application logic which meant the demo was production ready with no hidden technical nuances. We tested every component along with its variations.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1060px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.49999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Components library",
          "title": "Components library",
          "src": "/static/3d3356efd0debae2da6c8707b8a0ad8e/a48d4/components3.png",
          "srcSet": ["/static/3d3356efd0debae2da6c8707b8a0ad8e/a3397/components3.png 400w", "/static/3d3356efd0debae2da6c8707b8a0ad8e/a331c/components3.png 800w", "/static/3d3356efd0debae2da6c8707b8a0ad8e/a48d4/components3.png 1060w"],
          "sizes": "(max-width: 1060px) 100vw, 1060px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "designing-for-scale"
    }}>{`Designing for scale`}</h3>
    <p>{`For every element, we built a base component and all our variations inherited from the base. We could easily update requirements across the board without having to replace many snippets of code. It paved the way to add more accessibility support.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1060px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "151.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Components library",
          "title": "Components library",
          "src": "/static/d50dfbae8f4af547a0fee4ca8a2b04e7/a48d4/components4.png",
          "srcSet": ["/static/d50dfbae8f4af547a0fee4ca8a2b04e7/a3397/components4.png 400w", "/static/d50dfbae8f4af547a0fee4ca8a2b04e7/a331c/components4.png 800w", "/static/d50dfbae8f4af547a0fee4ca8a2b04e7/a48d4/components4.png 1060w"],
          "sizes": "(max-width: 1060px) 100vw, 1060px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "technical-variations"
    }}>{`Technical variations`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1418px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "121.49999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Components library",
          "title": "Components library",
          "src": "/static/503a8f30fa9e4d4b9fe8d7adbccbe010/cf1e4/components6.png",
          "srcSet": ["/static/503a8f30fa9e4d4b9fe8d7adbccbe010/a3397/components6.png 400w", "/static/503a8f30fa9e4d4b9fe8d7adbccbe010/a331c/components6.png 800w", "/static/503a8f30fa9e4d4b9fe8d7adbccbe010/cf1e4/components6.png 1418w"],
          "sizes": "(max-width: 1418px) 100vw, 1418px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "overall-challenges"
    }}>{`Overall challenges`}</h2>
    <p>{`We held biweekly meetings to discuss issues and solutions. Sometimes it was the user and not the tool that was the blocker.`}</p>
    <ul>
      <li parentName="ul">{`Falling back to designs in our kit wasn’t always the answer for a project.`}</li>
      <li parentName="ul">{`Variations are still variations. The easier it got to add, the harder it was to keep consistent since we can get experimentation happy. `}</li>
      <li parentName="ul">{`Maintenance is always a part of software development.`}</li>
      <li parentName="ul">{`Determining when an element became part of our kit wasn’t always straightforward. Did we only make scalable designs to start, or did we get innovative? Where did we strike this balance and which was the one that provided the most value to our customers?`}</li>
      <li parentName="ul">{`Responsive design is tricky when different people use the same component for different needs across multiple applications. Accounting for all use cases sometimes wasn’t possible.`}</li>
    </ul>
    <h2 {...{
      "id": "a-personal-journey"
    }}>{`A personal journey`}</h2>
    <p>{`Standardizing before optimizing helped clarify on bigger picture matters. Creating a system helped identify problems that we would have otherwise missed. Each pattern that was developed built on top of existing patterns which created a lot of relevance to what our user needs were. With more added devices, accessibility patterns, and languages to account for as technology evolves, design for scale has becoming much more relevant even in small applications so we can focus on solving real user problems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      